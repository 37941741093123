<template>
  <div class="marketingConfig-detail">
    <div class="title">
      <app-header :title="title" :isShowBack="true"></app-header>
    </div>
    <div class="content">
      <!-- 活动规则 -->
      <div class="content-box">
        <div class="content-title">活动规则</div>
        <el-form ref="myForm" :model="myForm" :rules="rules">
            <div class="content-row">
                活动类型<el-form-item style="display:inline-block;" prop="type">
                    <el-select style="margin: 10px" v-model="myForm.type" placeholder="请选择" :disabled="isEdit || isProceed">
                        <el-option label="买满送赠品" value="1"></el-option>
                        <!-- <el-option label="买满送优惠券" value="2"></el-option> -->
                    </el-select>
                </el-form-item>
            </div>
            <div class="content-row">
                活动名称<el-form-item style="display:inline-block;" prop="name">
                    <el-input style="margin: 10px" v-model="myForm.name" placeholder="请输入活动名称" :disabled="isEdit" maxlength="15"></el-input>
                </el-form-item>
            </div>
            <div class="content-row">
                每累计消费满<el-form-item style="display:inline-block;" prop="amount">
                    <el-input class="content-inputCalss" v-model="myForm.amount" :disabled="isEdit || isProceed"></el-input>
                </el-form-item>元送
                <el-form-item style="display:inline-block;" prop="quantity">
                    <el-input class="content-inputCalss" v-model="myForm.quantity" :disabled="isEdit || isProceed"></el-input>
                </el-form-item>件商品
            </div>
            <div class="content-row">
                活动开始时间<el-form-item style="display:inline-block;" prop="startDate">
                    <el-date-picker value-format='yyyy-MM-dd HH:mm:ss' v-model="myForm.startDate" style="margin: 10px" type="datetime" placeholder="选择日期" :disabled="isEdit || isProceed"> </el-date-picker>
                </el-form-item>
                活动结束时间<el-form-item  style="display:inline-block;" prop="endDate">
                    <el-date-picker value-format='yyyy-MM-dd HH:mm:ss' v-model="myForm.endDate" style="margin: 10px" type="datetime" placeholder="选择日期" :disabled="isEdit"> </el-date-picker>
                </el-form-item>
            </div>
        </el-form>
      </div>

      <!-- 参与活动门店 -->
      <div class="content-box">
        <div class="content-title">参与活动门店</div>
        <el-button type="primary" style="margin-left: 34px;" v-if="!isEdit && !isProceed" @click="openStore()">添加</el-button>
        <el-table :data="storeData" style="width: 80%; text-align: center; margin-left: 34px;" max-height="250">
          <el-table-column prop="groupName" label="门店名称" align="center"></el-table-column>
          <el-table-column prop="acceptPoint" label="取货点" align="center"></el-table-column>
          <el-table-column label="操作" align="center" v-if="!isEdit">
            <template slot-scope="scope">
              <el-button @click="openAcceptPoint(scope)" type="text" size="small"> 
                增加取货点</el-button>
              <el-button @click="deleteStore(scope.$index)" type="text" size="small" style="color:red" v-if="!isProceed"> 
                删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 参与活动赠品 -->
      <div class="content-box">
        <div class="content-title">参与活动赠品</div>
        <el-button type="primary" style="margin-left: 34px;" v-if="!isEdit" @click="openGoods()">添加</el-button>
        <el-table :data="goodsData" style="width: 80%; text-align: center; margin-left: 34px;" max-height="250">
          <el-table-column prop="name" label="商品名称" align="center"></el-table-column>
          <el-table-column prop="code" label="商品编号" align="center"></el-table-column>
          <el-table-column prop="goodsCategory" label="商品品类" align="center"></el-table-column>
          <el-table-column prop="costPrice" label="成本价" align="center"></el-table-column>
          <el-table-column prop="sellPrice" label="销售价" align="center"></el-table-column>
          <el-table-column prop="supplierName" label="供应商名称" align="center"></el-table-column>
          <el-table-column label="操作" align="center" v-if="!isEdit">
            <template slot-scope="scope">
              <el-button @click="deleteGoods(scope.$index)" type="text" size="small" style="color:red"> 
                删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 提交 -->
      <div class="content-submit" v-if="!isEdit">
          <el-button type="primary" @click="submit()" style="width:150px">提交</el-button>
      </div>
    </div>

    <!-- 添加活动门店弹窗 -->
    <el-dialog title="添加活动门店" :visible.sync="dialogStoreVisible">
      <el-form :inline="true">
        <el-form-item label="门店名称">
            <el-select v-model="searchForm.groupName" placeholder="请选择门店名称">
                <el-option v-for="item in options" :key="item.label" :label="item.label" :value="item.label"> </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="门店位置">
            <el-input placeholder="请输入门店位置" v-model="searchForm.address" clearable></el-input>
        </el-form-item>
        <el-form-item>
            <el-button icon="el-icon-search" type="primary" round @click="search()">查询</el-button>
        </el-form-item>
        <el-form-item>
            <el-button plain round @click="onSearchReset()">重置</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="storeDialogData" tooltip-effect="dark" style="width: 100%; margin-bottom: 20px;"
        @selection-change="storeSelectionChange">
        <el-table-column type="selection" width="55" align="center"> </el-table-column>
        <el-table-column prop="groupName" label="门店名称" align="center"> </el-table-column>
        <el-table-column prop="address" label="门店位置" align="center"> </el-table-column>
      </el-table>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
      <div slot="footer" style="text-align:center">
          <el-button type="primary" @click="storeSubmit()">确定</el-button>
      </div>
    </el-dialog>

    <!-- 添加取货点弹窗 -->
    <el-dialog title="设置取货点" :visible.sync="dialogPointVisible">
      <el-table :data="tableData" border>
        <el-table-column prop="controller" label="货管员" width="250">
          <template slot-scope="scope">
            <el-select v-model="tableData[scope.$index].id" placeholder="请选择">
              <el-option v-for="item in controllerList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="取货点">
          <template slot-scope="scope">
            <div style="margin: 0 0 0px 20px ">
              <el-button @click="numberChange(scope)">新增取货点</el-button>
            </div>
            <div>
                <span v-for="(item,index) in tableData[scope.$index].pointList" :key="index">
                    <div style="display: inline-block;">
                        <div style="display: flex; padding: 20px;">
                            <el-input style="display:inline-block;width:150px;" v-model.trim="item.pointName"  @input="changeMolecule(tableData,scope.$index,scope.row)"
                                minlength="1" maxlength="20" placeholder="请输入取货点名称">
                            </el-input>
                            <el-button class="closebtn" circle  size="mini" @click="deletePoint(scope,index)">X</el-button>
                        </div>
                    </div>
                </span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" style="text-align:center">
          <el-button type="primary" @click="acceptPointSubmit()">确定</el-button>
      </div>
    </el-dialog>

    <!-- 添加活动赠品弹窗 -->
    <el-dialog title="添加活动赠品" :visible.sync="dialogGoodsVisible">
      <div class="goods-search">
          <div>商品查询<span style="color:red;">*</span></div>
          <el-autocomplete v-model.trim="itemName" :fetch-suggestions="searchItem" placeholder="请输入商品名称/商品编号"
              @select="handleSelect" clearable></el-autocomplete>
      </div>
      <div>
        <el-table ref="multipleTable" :data="goodsDialogData" tooltip-effect="dark" border
            style="width: 100%;height:380px;overflow:auto;" @selection-change="goodsSelectionChange">
            <el-table-column type="selection" label="全选" width="55"></el-table-column>
            <el-table-column label="商品名称" prop="name"></el-table-column>
            <el-table-column label="商品编号" prop="code"></el-table-column>
            <el-table-column label="商品品类" prop="goodsCategory"></el-table-column>
            <el-table-column label="成本价（元/件）" prop="costPrice"></el-table-column>
            <el-table-column label="销售价（元/件）" prop="sellPrice"></el-table-column>
            <el-table-column label="供应商名称" prop="supplierName"></el-table-column>
        </el-table>
        <div class="edit-tableBut-box">
            <el-button class="edit-tableBut" @click="deleteRows()">删除行</el-button>
        </div>
        <div slot="footer" style="text-align:center">
            <el-button type="primary" @click="goodsSubmit()">提交</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  let consumeAmountVali = (rule, value, callback) => {
      var reg = /(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{1,2}$)/
      if (!reg.test(value)) {
          callback(
              new Error("请输入正数，且小数点后最多保留2位")
          );
      } else {
          callback();
      }
  };
  let consumeAmountGainNumVali = (rule, value, callback) => {
      var reg = /(^[1-9]{1}[0-9]*$)/
      if (!reg.test(value)) {
          callback(
              new Error("请输入大于0的整数")
          );
      } else {
          callback();
      }
  };
  export default {
      data() {
          return {
              id: '', 
              title:'活动配置',
              isEdit: false,
              isProceed: false, //是否是进行中状态
              tableData: [],
              myForm: {
                  type: '1',
                  name: '',
                  amount: '',
                  quantity: '',
                  startDate: '',
                  endDate: ''
              },
              rules: {
                  type: [
                      { required: true, message: '请选择活动类型', trigger: 'change' },
                  ],
                  name: [
                      { required: true, message: '请输入活动名称', trigger: 'blur' },
                  ],
                  amount: [{
                      validator: consumeAmountVali,
                      trigger: ['change']
                  }],
                  quantity: [{
                      validator: consumeAmountGainNumVali,
                      trigger: ['change']
                  }],
                  startDate: [
                    { required: true, message: '请选择日期', trigger: 'change' }
                  ],
                  endDate: [
                    { required: true, message: '请选择日期', trigger: 'change' }
                  ],
              },
              storeData: [], //参与活动门店数据
              goodsData: [], //参与活动赠品数据
              dialogStoreVisible: false, //门店弹窗
              storeDialogData: [], //添加门店数据
              searchForm: { //查询输入框
                  groupName: '',
                  address: ''
              },
              controllerList: [],
              options: [], //门店下拉数据
              limit: 10,
              skip: 1,
              total: 0,
              currentPage: 1,
              storeList: [], //当前选中的门店数据
              dialogPointVisible: false, //取货点弹窗
              minVal: 1, //最小的取货点数
              index: 0, //当前选中的门店行index
              oldPointVal: 1, //旧的取货点数
              dialogGoodsVisible: false, //添加赠品弹窗
              itemName: '', //商品查询
              goodsDialogData: [], //添加赠品数据
              multipleSection: [], 
              restaurants: [],
              codeList: [], //商品编号
          }
      },
      activated() {
          if (this.$route.params.type == 'add') {
            this.title = '添加活动'
            this.isEdit = false
            this.isProceed = false
            this.id = ''
            this.myForm = {
              type: '1',
              name: '',
              amount: '',
              quantity: '',
              startDate: '',
              endDate: ''
            }
            this.storeData = []
            this.goodsData = []
            this.codeList = []

          }else if (this.$route.params.type == 'show') {
            this.title = '活动配置'
            this.isEdit = true
            this.id = this.$route.query.id
            this.getDetail();
          }else if(this.$route.params.type == 'edit') {
            this.title = '活动配置'
            this.id = this.$route.query.id
            if(this.$route.query.state == 1) {
              this.isProceed = true
            }else {
              this.isProceed = false
            }
            this.isEdit = false
            this.getDetail();
          }
          // 对表单进行重置
          this.$nextTick(() => {
              this.$refs["myForm"].resetFields();
          })
      },
      methods: {
        // 获取活动配置详情
        getDetail() {
            this.post('mall-service/v1/activity/detail?id='+ this.id, {
                isUseResponse: true
            }).then(res => {
                this.myForm.type = res.type.toString();
                this.myForm.name = res.name;
                this.myForm.amount = res.amount;
                this.myForm.quantity = res.quantity;
                this.myForm.startDate = res.startDate;
                this.myForm.endDate = res.endDate;
                this.storeData = res.activityGroupVoList;
                res.activityItemsVoList.forEach(item => {
                  item.goodsCategory = item.typeName
                })
                this.goodsData = res.activityItemsVoList
                this.storeData.forEach(item => {
                  let teststr = []
                  item.cargoInfoVoList.forEach(val => {
                    teststr.push({id:val.id, acceptPointList:val.acceptPoints.split(',')})
                  })
                  item.cargoInfoReqList = teststr
                })
            })
        },

        formatDate () {
          var date = new Date();
          var YY = date.getFullYear() + '-';
          var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
          var DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
          var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
          var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
          var ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
          return YY + MM + DD + ' ' + hh + mm + ss;
        },

        // 提交活动配置
        submit() {
            this.$refs['myForm'].validate((valid) => {
                if (valid) {
                    if(this.myForm.startDate >= this.myForm.endDate) {
                        this.$message({
                            message: "结束时间必须大于开始时间",
                            type: "warning"
                        });
                        return
                    }
                    let date = this.formatDate()
                    if (this.$route.params.type == 'add' && this.myForm.startDate <= date ) {
                      this.$message({
                          message: "开始时间必须大于等于当前时间",
                          type: "warning"
                      });
                      return
                    }
                    if(this.myForm.endDate <= date){
                      this.$message({
                            message: "结束时间必须大于当前时间",
                            type: "warning"
                        });
                        return
                    }
                    let dto = {
                        type: this.myForm.type,
                        name: this.myForm.name,
                        amount: Number(this.myForm.amount),
                        quantity: Number(this.myForm.quantity),
                        startDate: this.myForm.startDate,
                        endDate: this.myForm.endDate,
                    };
                    if (this.id) {
                        dto.id = this.id;
                    }
                    let groupIdList = []
                    this.storeData.forEach(item => {
                      groupIdList.push({id:item.groupId, cargoInfoReqList:item.cargoInfoReqList})
                    })
                    dto.updateAcceptReqList = groupIdList
                    let itemIdList = []
                    this.goodsData.forEach(item => {
                      itemIdList.push(item.id)
                    })
                    dto.itemsIds = itemIdList
                    if(dto.updateAcceptReqList.length < 1) {
                      this.$message({
                          message: "请先选择参与活动的门店",
                          type: "warning"
                      });
                      return
                    }
                    if(dto.itemsIds.length < 1) {
                      this.$message({
                          message: "请先选择参与活动的赠品",
                          type: "warning"
                      });
                      return
                    }
                    if(!this.storeData.some(item=> item.acceptPoint)) {
                      this.$message({
                          message: "取货点不能都为空",
                          type: "warning"
                      });
                      return
                    }
                    this.post('mall-service/v1/activity/save', dto, {
                        isUseResponse: true
                    }).then(res => {
                        if (res.data.code == 0) {
                            this.$message({
                                showClose: true,
                                message: "提交成功!",
                                type: "success"
                            });
                            this.$router.push({
                              path: "/main/marketingConfig/list",
                            })
                        }
                    })
                }
            })
        },

        // 打开活动门店弹窗
        openStore() {
          this.dialogStoreVisible = true
          this.storeList = []
          this.searchForm = {
              groupName: '',
              address: ''
          }
          this.getStoreList()
          this.getStore()
        },

        // 活动门店弹窗分页
        handleSizeChange(val) {
            this.skip = 1;
				    this.currentPage = 1;
				    this.limit = val;
            this.getStore(this.searchForm)
				},

				//当前页改变时触发 跳转其他页
				handleCurrentChange(val) {
				    this.skip = val;
            this.getStore(this.searchForm)
				},

        // 获取总门店数据
        getStore(data) {
          let dto = {
              current: this.skip,
              pageSize: this.limit,
          }
          if(data) {
            dto.address = data.address,
            dto.groupName = data.groupName
          }
          this.post("/mall-service/v1/counterStores/getAcceptList", dto, {
              isUseResponse: true
          }).then(res => {
              this.total = res.data.data.total
              this.storeDialogData = res.data.data.records
              this.currentPage = this.skip
          })
        },

        //获取查询门店列表
        getStoreList() {
            let dto = {
                currentPage: 1,
                size: 99999,
            };
            this.post("/mall-service/v1/counterStores/getAllList", dto, {
                isUseResponse: true,
            }).then(res => {
                let storeList = res.data.data;
                storeList = JSON.parse(JSON.stringify(storeList).replace(/groupName/g, "label"));
                this.options = storeList
            });
        },

        // 门店查询按钮
        search() {
          this.limit=10
          this.skip=1
          this.getStore(this.searchForm)
        },

        // 门店重置按钮
        onSearchReset() {
          this.limit = 10
          this.skip = 1
          this.total = 0
          this.currentPage = 1
          this.searchForm = {
              groupName: '',
              address: ''
          }
          this.getStore()
        },

        // 当前选中的数据
        storeSelectionChange(val) {
          this.storeList = val
        },

        // 提交选择的活动门店
        storeSubmit() {
          this.storeData.forEach(item => {
            this.storeList.forEach((val,index) => {
              if(val.groupId.indexOf(item.groupId) !== -1) {
                this.storeList.splice(index,1)
              }
            })
          })
          this.storeData = this.storeData.concat(this.storeList)
          this.storeData.forEach(item => {
            let acceptPointStr = []
            let teststr = []
            item.cargoInfoVoList.forEach(val => {
              if(val.acceptPoints !== ''){
                acceptPointStr.push(val.acceptPoints)
                teststr.push({id:val.id, acceptPointList:val.acceptPoints.split(',')})
              }
            })
            item.acceptPoint = acceptPointStr.join(',')
            item.cargoInfoReqList = teststr
          })
          this.dialogStoreVisible = false
        },

        // 打开增加取货点弹窗
        openAcceptPoint(scope) {
          this.index = scope.$index
          this.tableData = scope.row.cargoInfoVoList
          this.tableData.forEach(item => {
            item.controller = item.name + '（' + item.phone + '）'
            if(item.acceptPoints !== '') {
              item.acceptPointsStr = item.acceptPoints.split(',')
              let test = []
              for(let i = 1; i <= item.acceptPointsStr.length; i++) {
                test.push({
                  pointName: item.acceptPointsStr[i-1],
                  pointCode: i
                })
              }
              item.pointList =  test
            }else{
              item.pointList =  [{ 
                pointName: '',
                pointCode: 1
              }]
            }
          })
          this.post('/mall-service/v1/counterStores/get-accept-point?groupId=' + scope.row.groupId, {
            isUseResponse: true,
          }).then(res => {
            res.forEach(item => {
              item.controllerLabel = item.name + '（' + item.phone + '）'
            })
            let controllerListArr = res
            controllerListArr = JSON.parse(JSON.stringify(controllerListArr).replace(/controllerLabel/g, "label"))
            controllerListArr = JSON.parse(JSON.stringify(controllerListArr).replace(/id/g, "value"))
            this.controllerList = controllerListArr
          })
          
          this.dialogPointVisible = true
        },

        // 刷新输入框
        changeMolecule(data, index, row) {
            this.$set(data, index, row)
        },

        // 新增取货点
        numberChange(scope) {
          if(scope.row.pointList.length > 99) {
            this.$message({
                message: "取货点数不得超过99个",
                type: "warning"
            });
            return
          }
          if(!scope.row.pointList.every(item => item.pointName)){
            this.$message({
                message: "请先输入取货点",
                type: "warning"
            });
            return
          }
          this.tableData[scope.$index].pointList.push({
            pointName: '',
            pointCode: this.tableData[scope.$index].pointList.length + 1,
          })
          this.tableData = Object.assign([],this.tableData)
        },

        // 删除取货点
        deletePoint(scope,index) {
          scope.row.pointList.splice(index,1)
          this.tableData = Object.assign([],this.tableData)
        },

        // 提交取货点
        acceptPointSubmit() {
          let test = []
          let test2 = []
          this.tableData.forEach(item => {
            if(item.pointList.length > 0) {
              let acceptPointList = []
              item.pointList.forEach(val => {
                acceptPointList.push(val.pointName)
                if(val.pointName !== '') {
                  test2.push(val.pointName)
                }
              })
              item.acceptPoints = acceptPointList.join(',')
              test.push({id:item.id, acceptPointList:acceptPointList})
            }
          })
          if(Array.from(new Set(test2)).length < test2.length) {
            return this.$message({
                message: "取货点名称不能重复",
                type: "warning"
            });
          }
          this.storeData[this.index].acceptPoint = test2.join(',')
          this.storeData[this.index].cargoInfoReqList = test
          this.storeData = Object.assign([],this.storeData)
          this.dialogPointVisible = false
        },

        // 删除门店
        deleteStore(val) {
          this.storeData.splice(val,1)
        },

        // 删除赠品
        deleteGoods(val) {
          this.goodsData.splice(val,1)
          this.codeList = []
          this.goodsData.forEach(item => {
            this.codeList.push(item.code)
          })
        },

        // 打开添加赠品弹窗
        openGoods() {
          this.goodsDialogData = []
          this.codeList = []
          this.goodsData.forEach(item => {
            this.codeList.push(item.code)
          })
          this.itemName = ''
          this.dialogGoodsVisible = true
        },

        // 选中的赠品
        goodsSelectionChange(val) {
          this.multipleSection = val;
        },

        // 查询商品
        searchItem(queryString, cb) {
            var restaurants = [];
            if(!this.itemName) {
                cb(restaurants);
                return
            }
            restaurants = this.restaurants;
            this.get('mall-service/items/queryInItemsByNameOrCode?data=' + this.itemName).then(res => {
                restaurants = res;
                if (restaurants.length > 0) {
                    restaurants.forEach(item => {
                        if(item.supplierName) {
                            item.value = item.name + "/" + item.supplierName;
                        }else {
                            item.value = item.name;
                        }
                    })
                }
                cb(restaurants);
            })
        },

        // 选择商品
        handleSelect(item) {
            if (this.codeList.indexOf(item.code) != -1) {
                this.$message({
                    message: '商品编号重复！',
                    type: 'warning'
                })
                this.$refs.multipleTable.setCurrentRow(item);
                return

            }
            console.log(item);
            this.goodsDialogData.unshift({
                id: item.id,
                name: item.name,
                code: item.code,
                costPrice: item.costPrice ? Number(item.costPrice).toFixed(2) : 0.00,
                sellPrice: item.sellPrice ? Number(item.sellPrice).toFixed(2) : 0.00,
                goodsImages: item.pic,
                goodsCategory: item.category,
                count: 1,
                type: 1, //1-入库 2-出库
                index: this.goodsDialogData.length,
                supplierName: item.supplierName ? item.supplierName : '--',
                supplierId: item.supplierId ? item.supplierId : null,
            })
            this.goodsDialogData.forEach(it => {
                if (this.codeList.indexOf(it.code) == -1) {
                    this.codeList.push(it.code);
                }
            })
            this.itemName = '';
        },

        // 提交赠品
        goodsSubmit() {
          if (this.goodsDialogData.length < 1) {
              this.$message({
                  message: '至少要录入一条商品数据',
                  type: 'warning'
              })
              return
          }
          this.goodsData = this.goodsData.concat(this.goodsDialogData)
          this.codeList = []
          this.goodsData.forEach(item => {
            this.codeList.push(item.code)
          })
          this.dialogGoodsVisible = false
        },

        // 删除行按钮
        deleteRows() {
          if (this.multipleSection < 1) {
              this.$message({
                  message: '至少选择一项！',
                  type: 'warning',
              })
              return
          }
          let selectIdList = [];
          this.multipleSection.forEach(item => {
              selectIdList.push(item.index);
          })
          let saveList = [];
          this.codeList = [];
          this.goodsDialogData.forEach((item, index) => {
              if (selectIdList.indexOf(item.index) == -1) {
                  saveList.push(item);
                  this.codeList.push(item.code);
              }
          })
          this.goodsDialogData = saveList;
        },
      }
  }
</script>
<style lang="scss" scoped>
  .el-dialog {
    margin-top: 28vh !important;
    width: 50%;
  }

  .content {
      margin-top: 25px;
  }
  .content-box{
    padding-bottom: 50px;
  }

  .content-title {
      margin-left: 10px;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 15px;
  }

  .content-row {
      margin-left: 34px;
      font-size: 15px;
  }

  .content-inputCalss {
      width: 100px;
      padding: 10px;
  }
  .content-pickerCalss {
      padding: 10px;
  }

  .content-submit {
      text-align: center;
      width: 80%;
      padding-bottom: 20px;
  }
  .goods-search {
      margin: 20px 0;
      display: flex;

      div {
          line-height: 35px;
          padding-right: 5px;
      }
  }
  .edit-tableBut-box{
      margin: 10px 0;
  }
  .closebtn{
    height: 26px;
    margin-left: -13px;
    margin-top: -10px;
    z-index: 2;
  }
</style>