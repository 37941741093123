var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "marketingConfig-detail" },
    [
      _c(
        "div",
        { staticClass: "title" },
        [_c("app-header", { attrs: { title: _vm.title, isShowBack: true } })],
        1
      ),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "content-box" },
          [
            _c("div", { staticClass: "content-title" }, [_vm._v("活动规则")]),
            _c(
              "el-form",
              { ref: "myForm", attrs: { model: _vm.myForm, rules: _vm.rules } },
              [
                _c(
                  "div",
                  { staticClass: "content-row" },
                  [
                    _vm._v("\n                活动类型"),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { display: "inline-block" },
                        attrs: { prop: "type" }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { margin: "10px" },
                            attrs: {
                              placeholder: "请选择",
                              disabled: _vm.isEdit || _vm.isProceed
                            },
                            model: {
                              value: _vm.myForm.type,
                              callback: function($$v) {
                                _vm.$set(_vm.myForm, "type", $$v)
                              },
                              expression: "myForm.type"
                            }
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "买满送赠品", value: "1" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "content-row" },
                  [
                    _vm._v("\n                活动名称"),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { display: "inline-block" },
                        attrs: { prop: "name" }
                      },
                      [
                        _c("el-input", {
                          staticStyle: { margin: "10px" },
                          attrs: {
                            placeholder: "请输入活动名称",
                            disabled: _vm.isEdit,
                            maxlength: "15"
                          },
                          model: {
                            value: _vm.myForm.name,
                            callback: function($$v) {
                              _vm.$set(_vm.myForm, "name", $$v)
                            },
                            expression: "myForm.name"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "content-row" },
                  [
                    _vm._v("\n                每累计消费满"),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { display: "inline-block" },
                        attrs: { prop: "amount" }
                      },
                      [
                        _c("el-input", {
                          staticClass: "content-inputCalss",
                          attrs: { disabled: _vm.isEdit || _vm.isProceed },
                          model: {
                            value: _vm.myForm.amount,
                            callback: function($$v) {
                              _vm.$set(_vm.myForm, "amount", $$v)
                            },
                            expression: "myForm.amount"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v("元送\n                "),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { display: "inline-block" },
                        attrs: { prop: "quantity" }
                      },
                      [
                        _c("el-input", {
                          staticClass: "content-inputCalss",
                          attrs: { disabled: _vm.isEdit || _vm.isProceed },
                          model: {
                            value: _vm.myForm.quantity,
                            callback: function($$v) {
                              _vm.$set(_vm.myForm, "quantity", $$v)
                            },
                            expression: "myForm.quantity"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v("件商品\n            ")
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "content-row" },
                  [
                    _vm._v("\n                活动开始时间"),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { display: "inline-block" },
                        attrs: { prop: "startDate" }
                      },
                      [
                        _c("el-date-picker", {
                          staticStyle: { margin: "10px" },
                          attrs: {
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            type: "datetime",
                            placeholder: "选择日期",
                            disabled: _vm.isEdit || _vm.isProceed
                          },
                          model: {
                            value: _vm.myForm.startDate,
                            callback: function($$v) {
                              _vm.$set(_vm.myForm, "startDate", $$v)
                            },
                            expression: "myForm.startDate"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v("\n                活动结束时间"),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { display: "inline-block" },
                        attrs: { prop: "endDate" }
                      },
                      [
                        _c("el-date-picker", {
                          staticStyle: { margin: "10px" },
                          attrs: {
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            type: "datetime",
                            placeholder: "选择日期",
                            disabled: _vm.isEdit
                          },
                          model: {
                            value: _vm.myForm.endDate,
                            callback: function($$v) {
                              _vm.$set(_vm.myForm, "endDate", $$v)
                            },
                            expression: "myForm.endDate"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "content-box" },
          [
            _c("div", { staticClass: "content-title" }, [
              _vm._v("参与活动门店")
            ]),
            !_vm.isEdit && !_vm.isProceed
              ? _c(
                  "el-button",
                  {
                    staticStyle: { "margin-left": "34px" },
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        _vm.openStore()
                      }
                    }
                  },
                  [_vm._v("添加")]
                )
              : _vm._e(),
            _c(
              "el-table",
              {
                staticStyle: {
                  width: "80%",
                  "text-align": "center",
                  "margin-left": "34px"
                },
                attrs: { data: _vm.storeData, "max-height": "250" }
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "groupName",
                    label: "门店名称",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "acceptPoint",
                    label: "取货点",
                    align: "center"
                  }
                }),
                !_vm.isEdit
                  ? _c("el-table-column", {
                      attrs: { label: "操作", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function($event) {
                                      _vm.openAcceptPoint(scope)
                                    }
                                  }
                                },
                                [_vm._v(" \n                增加取货点")]
                              ),
                              !_vm.isProceed
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { color: "red" },
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function($event) {
                                          _vm.deleteStore(scope.$index)
                                        }
                                      }
                                    },
                                    [_vm._v(" \n                删除")]
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "content-box" },
          [
            _c("div", { staticClass: "content-title" }, [
              _vm._v("参与活动赠品")
            ]),
            !_vm.isEdit
              ? _c(
                  "el-button",
                  {
                    staticStyle: { "margin-left": "34px" },
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        _vm.openGoods()
                      }
                    }
                  },
                  [_vm._v("添加")]
                )
              : _vm._e(),
            _c(
              "el-table",
              {
                staticStyle: {
                  width: "80%",
                  "text-align": "center",
                  "margin-left": "34px"
                },
                attrs: { data: _vm.goodsData, "max-height": "250" }
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "name", label: "商品名称", align: "center" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "code", label: "商品编号", align: "center" }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "goodsCategory",
                    label: "商品品类",
                    align: "center"
                  }
                }),
                _c("el-table-column", {
                  attrs: { prop: "costPrice", label: "成本价", align: "center" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "sellPrice", label: "销售价", align: "center" }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "supplierName",
                    label: "供应商名称",
                    align: "center"
                  }
                }),
                !_vm.isEdit
                  ? _c("el-table-column", {
                      attrs: { label: "操作", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { color: "red" },
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function($event) {
                                      _vm.deleteGoods(scope.$index)
                                    }
                                  }
                                },
                                [_vm._v(" \n                删除")]
                              )
                            ]
                          }
                        }
                      ])
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        ),
        !_vm.isEdit
          ? _c(
              "div",
              { staticClass: "content-submit" },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { width: "150px" },
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        _vm.submit()
                      }
                    }
                  },
                  [_vm._v("提交")]
                )
              ],
              1
            )
          : _vm._e()
      ]),
      _c(
        "el-dialog",
        {
          attrs: { title: "添加活动门店", visible: _vm.dialogStoreVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogStoreVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { inline: true } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "门店名称" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择门店名称" },
                      model: {
                        value: _vm.searchForm.groupName,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "groupName", $$v)
                        },
                        expression: "searchForm.groupName"
                      }
                    },
                    _vm._l(_vm.options, function(item) {
                      return _c("el-option", {
                        key: item.label,
                        attrs: { label: item.label, value: item.label }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "门店位置" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入门店位置", clearable: "" },
                    model: {
                      value: _vm.searchForm.address,
                      callback: function($$v) {
                        _vm.$set(_vm.searchForm, "address", $$v)
                      },
                      expression: "searchForm.address"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-search",
                        type: "primary",
                        round: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.search()
                        }
                      }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { plain: "", round: "" },
                      on: {
                        click: function($event) {
                          _vm.onSearchReset()
                        }
                      }
                    },
                    [_vm._v("重置")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%", "margin-bottom": "20px" },
              attrs: { data: _vm.storeDialogData, "tooltip-effect": "dark" },
              on: { "selection-change": _vm.storeSelectionChange }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55", align: "center" }
              }),
              _c("el-table-column", {
                attrs: { prop: "groupName", label: "门店名称", align: "center" }
              }),
              _c("el-table-column", {
                attrs: { prop: "address", label: "门店位置", align: "center" }
              })
            ],
            1
          ),
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.currentPage,
              "page-sizes": [10, 20, 30, 50],
              "page-size": _vm.limit,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          }),
          _c(
            "div",
            {
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.storeSubmit()
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "设置取货点", visible: _vm.dialogPointVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogPointVisible = $event
            }
          }
        },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.tableData, border: "" } },
            [
              _c("el-table-column", {
                attrs: { prop: "controller", label: "货管员", width: "250" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择" },
                            model: {
                              value: _vm.tableData[scope.$index].id,
                              callback: function($$v) {
                                _vm.$set(_vm.tableData[scope.$index], "id", $$v)
                              },
                              expression: "tableData[scope.$index].id"
                            }
                          },
                          _vm._l(_vm.controllerList, function(item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value }
                            })
                          }),
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "取货点" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          { staticStyle: { margin: "0 0 0px 20px" } },
                          [
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function($event) {
                                    _vm.numberChange(scope)
                                  }
                                }
                              },
                              [_vm._v("新增取货点")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          _vm._l(
                            _vm.tableData[scope.$index].pointList,
                            function(item, index) {
                              return _c("span", { key: index }, [
                                _c(
                                  "div",
                                  { staticStyle: { display: "inline-block" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          padding: "20px"
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          staticStyle: {
                                            display: "inline-block",
                                            width: "150px"
                                          },
                                          attrs: {
                                            minlength: "1",
                                            maxlength: "20",
                                            placeholder: "请输入取货点名称"
                                          },
                                          on: {
                                            input: function($event) {
                                              _vm.changeMolecule(
                                                _vm.tableData,
                                                scope.$index,
                                                scope.row
                                              )
                                            }
                                          },
                                          model: {
                                            value: item.pointName,
                                            callback: function($$v) {
                                              _vm.$set(
                                                item,
                                                "pointName",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "item.pointName"
                                          }
                                        }),
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "closebtn",
                                            attrs: { circle: "", size: "mini" },
                                            on: {
                                              click: function($event) {
                                                _vm.deletePoint(scope, index)
                                              }
                                            }
                                          },
                                          [_vm._v("X")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ])
                            }
                          ),
                          0
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.acceptPointSubmit()
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "添加活动赠品", visible: _vm.dialogGoodsVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogGoodsVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "goods-search" },
            [
              _c("div", [
                _vm._v("商品查询"),
                _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")])
              ]),
              _c("el-autocomplete", {
                attrs: {
                  "fetch-suggestions": _vm.searchItem,
                  placeholder: "请输入商品名称/商品编号",
                  clearable: ""
                },
                on: { select: _vm.handleSelect },
                model: {
                  value: _vm.itemName,
                  callback: function($$v) {
                    _vm.itemName = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "itemName"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-table",
                {
                  ref: "multipleTable",
                  staticStyle: {
                    width: "100%",
                    height: "380px",
                    overflow: "auto"
                  },
                  attrs: {
                    data: _vm.goodsDialogData,
                    "tooltip-effect": "dark",
                    border: ""
                  },
                  on: { "selection-change": _vm.goodsSelectionChange }
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", label: "全选", width: "55" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "商品名称", prop: "name" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "商品编号", prop: "code" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "商品品类", prop: "goodsCategory" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "成本价（元/件）", prop: "costPrice" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "销售价（元/件）", prop: "sellPrice" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "供应商名称", prop: "supplierName" }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "edit-tableBut-box" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "edit-tableBut",
                      on: {
                        click: function($event) {
                          _vm.deleteRows()
                        }
                      }
                    },
                    [_vm._v("删除行")]
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.goodsSubmit()
                        }
                      }
                    },
                    [_vm._v("提交")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }